import React from "react";

import bluemap from "../../assets/images/about/person-trainer-blue-map.png";

import { Link } from "gatsby";

export default function Persontrainer() {
  return (
    <section id="heartSpots" className="person-trainer text-center pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-9 cent-item">
            <h3 className="heading-wrapper white-text"> In Person with a Trainer </h3>
            <span className="vertical-blue-line bg-white"> </span>
            <p className="theme-p-tag white-text">
              {" "}
              Experience meditation, ask questions, and meet other like-minded
              seekers. There are 6,000 Heartfulness Centers worldwide. To find
              the HeartSpot near you:
            </p>
            <Link to="https://heartspots.heartfulness.org/" className="btn btn-blue button-bg-white" target="_blank"
                    rel="noreferrer"> Find your Trainer </Link>
            <img src={bluemap} alt="youtube" />
          </div>
        </div>
      </div>
    </section>
  );
}
