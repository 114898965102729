import React from "react";

import heartsappmobile from "../../assets/images/about/heartsapp-screen-img-1.png";

import playstorebutton from "../../assets/images/about/google-play-btn-img-1.png";

import appstorebutton from "../../assets/images/about/app-store-btn-img-1.png";

import { Link } from "gatsby";

export default function Heartsappheartfulness() {
  return (
    <section className="heartsapp-heartfulness-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-6 heartsapp-padding">
            <div className="vertical-blk-center">
              <h2 className="gotham-book theme-color mb-3">
                {" "}
                HeartsApp <span className="text-black"> by Heartfulness </span>
              </h2>
              <h4 className="theme-h4 meditate-font"> Meditate Anytime Anywhere</h4>
              <p className="theme-p-tag meditate-p-tag">
                {" "}
                Mobile App to relax, meditate,<br/> rejuvenate and connect. Meditate
                with <br/>a trainer online using the App
              </p>

              <div>
                <Link
                  to="//play.google.com/store/apps/details?id=com.hfn.unified"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    className="mg-right20 mobile-mgbottom20"
                    src={playstorebutton}
                    alt="Universal Prayer"
                  />{" "}
                </Link>
                <Link
                  to="//itunes.apple.com/us/app/heartsapp-by-heartfulness/id1438627629?mt=8"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appstorebutton} alt="Universal Prayer" />
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <img src={heartsappmobile} alt="Universal Prayer" />
          </div>
        </div>
      </div>
    </section>
  );
}
