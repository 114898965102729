import React from "react";

import magazinenov from "../../assets/images/home/hfn-magazinenew-nov2020-img.png";

export default function Magazineblue() {
  return (
    <div className="bg-magazinenew-sec py-5">
      <div className="hfn-container px-3">
        <div className="row">
          <div className="col-md-4 offset-md-1 text-center">
            <img className="img-fluid maxizine-widh" src={magazinenov} alt="youtube" />
          </div>
          <div className="col-md-5 text-md-left">
            <div className="vertical-blk-center">
              <h1 className="display-4 maxazine-big-text">
                <span className="small">Heartfulness </span>
                <span className="small text-uppercase dis-block magazine-text">Magazine</span>
              </h1>
           
              <p className="theme-p-tag py-2">
                Receive useful tips from around the world on lifestyle,
                relationships, health and well-being, Yoga, Heartfulness and
                much more.
              </p>
              <a href="https://www.heartfulnessmagazine.com/" className="btn btn-blue"  target="_blank"
                    rel="noreferrer"> View All </a>
            </div>
          </div>
          <div className="col-md-3">

          </div>
        </div>
      </div>
    </div>
  );
}
