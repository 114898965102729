import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import AboutHeartfulness from "../components/about/about-heartfulnes";

import Aboutbreadcreumb from "../components/about/about-breadcumbs";

import Fourguides from "../components/about/four-guides";

import Persontrainer from "../components/about/person-trainer";

import Heartfulnessguide from "../components/home/ourspiritualjourney-hasguidebooks";

import Magazineblue from "../components/home/magazine-blue";

import Testimonailslider from "../components/home/testimonal";

import Heartsappheartfulness from "../components/about/heartsapp-heartfulness";

// markup
const AboutPage = () => {
  return (
    <div>
      <Seo title="About" />

      <Layout isSticky>
        <section>
          <Aboutbreadcreumb />
        </section>
        <section>
          <AboutHeartfulness />
        </section>

        <section>
          <Fourguides />
        </section>
        <section>
          <Persontrainer />
        </section>

        <section>
          <Heartsappheartfulness />
        </section>

        <section>
          <Heartfulnessguide />
        </section>

        <section>
          <Magazineblue />
        </section>

        <section>
          <Testimonailslider />
        </section>
      </Layout>
    </div>
  );
};

export default AboutPage;
