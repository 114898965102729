import React from "react";

import { Link } from "gatsby";

import lalajipic from "../../assets/images/about/lalaji-master-history-1.png";

import babujipic from "../../assets/images/about/babuji-master-history-1.png";

import charijipic from "../../assets/images/about/chariji-master-history-1.png";

import kamalashpic from "../../assets/images/about/kamlesh-d-patel-daaji-1.png";

export default function Fourguides() {
  return (
    <section className="fourguides-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="heading-wrapper" style={{color:"#2A85D0"}}> The Four Guides </h3>
            <span className="vertical-blue-line"> </span>
          </div>

          <div className="col-lg-3 col-md-6 pb-4">
            <div className="hfnmaster-hover">
              <div className="hfnmaster-blksec">
                <img src={lalajipic} alt="Universal Prayer" />

                <div className="photo-bottom">
                  <h5 className="text-center gotham-medium mb-0 pt-1">
                    Lalaji
                  </h5>
                  <h5 className="text-center gotham-medium pb-1">
                    1873-1931
                  </h5>
                </div>
              </div>
              <div className="speaker-overlay">
                <div className="hfnmaster-text">
                  <p>
                    Shri Ram Chandra of Fatehgarh, affectionately known as
                    Lalaji, was the first guru in the Heartfulness tradition. He
                    revived the ancient art of Yogic Transmission so that all
                    seekers with sincere interest in their evolution could
                    experience…
                  </p>
                  <p class="pt-5 text-right">
                    <Link to="/lalaji/" className="btn text-white">
                      Read More
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 pb-4">
            <div className="hfnmaster-hover">
              <div className="hfnmaster-blksec">
                <img src={babujipic} alt="Universal Prayer" />

                <div class="photo-bottom">
                  <h5 class="text-center gotham-medium mb-0 pt-1">
                    Babuji
                  </h5>
                  <h5 class="text-center gotham-medium pb-1">
                    1899-1983
                  </h5>
                </div>
              </div>
              <div className="speaker-overlay">
                <div className="hfnmaster-text">
                  <p>
                    Lalaji’s successor was also called Shri Ram Chandra, and is
                    known as Babuji. He too lived in Uttar Pradesh, in the town
                    of Shahjahanpur. Living a family life, he had a career as a
                    clerk in the local court for more than thirty years…
                  </p>
                  <p class="pt-5 text-right">
                    <Link to="/babuji/" className="btn text-white">
                      Read More
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 pb-4">
            <div className="hfnmaster-hover">
              <div className="hfnmaster-blksec">
                <img src={charijipic} alt="Universal Prayer" />

                <div class="photo-bottom">
                  <h5 class="text-center gotham-medium mb-0 pt-1">
                    Chariji
                  </h5>
                  <h5 class="text-center gotham-medium pb-1">
                    1927-2014
                  </h5>
                </div>
              </div>
              <div className="speaker-overlay">
                <div className="hfnmaster-text">
                  <p>
                    Babuji’s successor was Parthasarathi Rajagopalachari, and he
                    is known as Chariji. He was born near Chennai in the South
                    of India and was educated in the British system, graduating
                    from Benaras Hindu University with a Bachelor of Science
                    degree…
                  </p>
                  <p class="text-right">
                    <Link to="/chariji/" className="btn text-white">
                      Read More
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 pb-4">
            <div className="hfnmaster-hover">
              <div className="hfnmaster-blksec">
                <img src={kamalashpic} alt="Universal Prayer" />

                <div class="photo-bottom">
                  <h5 class="text-center gotham-medium mb-0 pt-1">
                    Kamlesh Patel
                  </h5>
                  <h5 class="text-center gotham-medium pb-1">
                    1956-Present
                  </h5>
                </div>
              </div>
              <div className="speaker-overlay">
                <div className="hfnmaster-text">
                  <p>
                    Known to many as Daaji, his teachings on Heartfulness
                    Meditation arise from his personal experience, while
                    reflecting his deep spirit of enquiry and respect for the
                    world’s great spiritual traditions and scientific
                    advancements…
                  </p>
                  <p class="pt-5 text-right">
                  <Link to="/kamlesh-d-patel/" className="btn text-white">
                      Read More
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
