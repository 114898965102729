import React from "react";

import { Link } from "gatsby";

import aboutheartfulnesspic from "../../assets/images/about/about-heartfulness-pic.png";

export default function AboutHeartfulness() {
  return (
    <section className="aboutheartfulness-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="gotham-book my-n1 humanity-font-weight"> Humanity's way forward </h2>
            <h2 className="gotham-book theme-color mb-3">
              {" "}
              is through the heart
            </h2>
            <div className="title-blueunderline ml-md-0"></div>
            <p className="theme-p-tag theme-ptag-humanity">
              {" "}
              Heartfulness is a simple and subtle practice of meditation that
              connects each of us with the light and love in our hearts. Through
              our network of thousands of global <Link to="#heartSpots" className="hfn-heartspots" style={{color:'#fd5a63'}}>
              HeartSpots
                    </Link>  and one magnificent
              smartphone app, we offer the world a daily practice that awakens
              our potential for simple, joyful existence. This precious gift of
              human transformation is offered free of charge.
            </p>
          </div>
          <div className="col-md-6">
            <img src={aboutheartfulnesspic} alt="Universal Prayer" />
          </div>
        </div>
      </div>
    </section>
  );
}
