import React from "react";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import arrowleft from "../../assets/images/testimonial-slider/Icon ionic-ios-arrow-dropleft.png";


import arrowright from "../../assets/images/testimonial-slider/Icon ionic-ios-arrow-dropright.png";

export default function Testimonailslider() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 768,
      },
      items: 3,
    },
    mobile: {
      breakpoint: {
        max: 767,
        min: 0,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()}>
            <img src={arrowleft}  />
          </button>
          <button onClick={() => next()}>
            <img src={arrowright} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="testimonial-slider container pad60">
      <div className="row">
        <div className="col-md-12">
          <h3 className="heading-wrapper"> Testimonials </h3>
          <span className="vertical-blue-line"> </span>
        </div>

        <div className="carousel-section">
          <Carousel
            arrows={true}
            showDots={true}
            autoPlaySpeed={3000}
            draggable
            infinite
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            renderButtonGroupOutside={false}
            swipeable
            responsive={responsive}
           
          
            // customButtonGroup={<ButtonGroup />}
          >
              <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body">
                  <p class="testimonialcard-text mb-5">
                    {" "}
                    Every day we started with Heartfulness Meditation. The children were able to get into a relaxed state for between 10 and 15 minutes I was extremely happy when there were days when the children would come into the room asking to meditate.
                    <br/>
                    <br/>
                   
                    <br/>
                  </p>
                  <h4 className="theme-h4 theme-color testimonal-name">
                    {" "}
                    Lynn Copeland{" "}
                  </h4>
                  <p class="testimonialcard-text"> Urban Ridge Realty, <br/>Juvenile Detention Village </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body">
                  <p class="testimonialcard-text mb-4">
                    {" "}
                    This is an amazing program that you have. all of the presenters were extremely professional, and I could feel the passion as they presented. I feel honoured to have been a part of your program and would invite you and the Heartfulness Institute to come on a regular basis. I know there are many others who would enjoy the program.
                    <br/>
                    <br/>
                  
                  </p>
                  <h4 className="theme-h4 theme-color testimonal-name"> Dr Hoyt Ross</h4>
                  <p class="testimonialcard-text">
                    {" "}
                    CEO, Promise Hospital,<br/> Oxford, FL, USA
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body">
                  <p class="testimonialcard-text mb-4">
                    {" "}
                    I am short of words as meditation has very positive results. Physicians participating in Heartfulness training report enhanced personal well-being, decreased burnout, and improved attitude towards patient care. I am sure you will also realize a positive change.
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </p>
                  <h4 className="theme-h4 theme-color testimonal-name"> Dr Mansee Thakur </h4>
                  <p class="testimonialcard-text">
                    {" "}
                    I/C Director, MGM Institute of <br/> Health Science, India.{" "}
                  </p>
                </div>
              </div>
            </div>

          

            <div className="col-md-12 mar0">
              <div className="testimonial-card1">
                <div className="testimonial-card-body">
                  <p class="testimonialcard-text mb-4">
                    {" "}
                    I have promoted meditation to my patients for years as
                    medical studies show its ability to help rebalance
                    sympathetic/parasympathetic tone. It can lower cortisol
                    levels, reduce blood pressure and improve sleep patterns. I
                    have learned many things from my experience. I would
                    recommend meditation to every living being.
                    <br/>
                    <br/>
                    <br/>
                  </p>
                  <h4 className="theme-h4 theme-color testimonal-name"> Dr Gary Huber </h4>
                  <p class="testimonialcard-text"> Ohio </p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
